/*!
 *
 *
 * Designed And Developed By Codixel
 * http: //codixel.tech
 * 2023 (06)
 *
 *
 */

// script for site
//
//jquery
window.$ = window.jQuery = require("jquery");

import AOS from "aos";

AOS.init({
  once: true,
});
